var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.event)?_c('div',{staticClass:"bg-white border pt-4 pb-2 rounded-md shadow hover:shadow-2xl"},[_c('div',{staticClass:"-mt-4 pl-4 w-48 uppercase text-sm text-left font-medium bg-gray-200 border-r border-b"},[_c('span',{staticClass:"mr-1"},[_c('fa',{attrs:{"icon":['fal', 'calendar']}})],1),_vm._v(" "+_vm._s(_vm.$t('event_card_event'))+" ")]),_c('div',{staticClass:"px-4 py-2"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"text-2xl font-medium"},[_c('router-link',{attrs:{"to":{
            name: 'event',
            params: {
              cid: _vm.event.companyId,
              aid: _vm.event.accountId,
              eid: _vm.event.id,
            },
          }}},[_c('interval-name',{attrs:{"interval":_vm.event}})],1),(_vm.attCount)?_c('span',{staticClass:"mx-1 p-1 text-gray-600 rounded-l"},[_c('fa',{attrs:{"icon":['fal', 'paperclip']}})],1):_vm._e()],1),(_vm.canRemove)?_c('sqr-button',{attrs:{"icon":"trash","tooltip":"event_remove","is-loading":_vm.removing[_vm.event.id]},on:{"click":function($event){return _vm.removeConfirm(_vm.event)}}}):_vm._e()],1),_c('sqr-error-note',{attrs:{"error":_vm.removeError[_vm.event.id]}})],1),_c('details',{},[(_vm.entry)?_c('summary',{staticClass:"flex items-center text-2xl py-2 px-4 border-t border-b",style:({ 'border-left': '6px solid ' + _vm.entry.color })},[_c('span',{staticClass:"mr-2"},[_c('fa',{attrs:{"icon":['fal', _vm.entry.icon]}})],1),_c('span',[_vm._v(_vm._s(_vm._f("name")(_vm.entry)))])]):_vm._e(),(_vm.event.entries)?_c('div',_vm._l((_vm.event.entries),function(entry){return _c('div',{key:entry.date,staticClass:"border-b"},[_c('div',{staticClass:"px-4 pt-2",style:({ 'border-left': '6px solid ' + entry.color })},[_c('span',{staticClass:"has-text-weight-semibold mr-2"},[_vm._v(_vm._s(_vm._f("weekDayLong")(entry.date)))]),_c('span',{staticClass:"has-text-weight-normal"},[_vm._v("("+_vm._s(_vm._f("week")(entry.date))+")")])]),_c('week-day-entry',{attrs:{"can-remove":false,"entry":entry,"disabled":""}})],1)}),0):(_vm.event.entry)?_c('div',[_c('week-day-entry',{attrs:{"can-remove":false,"entry":_vm.event.entry,"disabled":""}})],1):_vm._e()]),(_vm.isManagerRW)?_c('div',{staticClass:"px-4 pt-2 pb-1"},[_c('status-buttons',{staticClass:"is-right",attrs:{"status":_vm.event.status,"is-loading":_vm.moderating[_vm.event.id]},on:{"change":function($event){return _vm.moderate(_vm.event, $event)}}}),_c('sqr-error-note',{staticClass:"field",attrs:{"error":_vm.moderateError[_vm.event.id]}})],1):_c('div',{staticClass:"px-4 pt-2 pb-1 text-right"},[_c('status-tag',{attrs:{"record":_vm.event}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }