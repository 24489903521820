<template>
  <div
    class="tag is-medium has-tooltip-left"
    :class="tagClass"
    v-if="status"
    :data-tooltip="tooltip"
  >
    {{ $t('statuses_' + status) }}
  </div>
</template>

<script>
import datetime from '@/filters/datetime';

export default {
  name: 'StatusTag',
  props: {
    record: Object,
  },
  computed: {
    status() {
      return this.record?.status;
    },
    tagClass() {
      const base = this.tooltip ? ['tooltip'] : [];
      switch (this.status) {
        case 'accepted':
          return [...base, 'is-success', 'has-tooltip-success'];
        case 'denied':
          return [...base, 'is-danger', 'has-tooltip-danger'];
        default:
          return [...base, 'is-light', 'has-tooltip-light'];
      }
    },
    tooltip() {
      let updater = this.record?.updated;
      if (this.record?.status === 'accepted') {
        updater = this.record?.accepted;
      } else if (this.record?.status === 'denied') {
        updater = this.record?.denied;
      }
      return updater
        ? [datetime(updater.date), updater.displayName].join(', ')
        : 'N/A';
    },
  },
};
</script>
