<template>
  <div
    class="bg-white border pt-4 pb-2 rounded-md shadow hover:shadow-2xl"
    v-if="event"
  >
    <div
      class="-mt-4 pl-4 w-48 uppercase text-sm text-left font-medium bg-gray-200 border-r border-b"
    >
      <span class="mr-1"><fa :icon="['fal', 'calendar']" /></span>
      {{ $t('event_card_event') }}
    </div>
    <div class="px-4 py-2">
      <div class="flex justify-between items-center">
        <div class="text-2xl font-medium">
          <router-link
            :to="{
              name: 'event',
              params: {
                cid: event.companyId,
                aid: event.accountId,
                eid: event.id,
              },
            }"
          >
            <interval-name :interval="event" />
          </router-link>
          <span v-if="attCount" class="mx-1 p-1 text-gray-600 rounded-l">
            <fa :icon="['fal', 'paperclip']" />
          </span>
        </div>
        <sqr-button
          v-if="canRemove"
          @click="removeConfirm(event)"
          icon="trash"
          tooltip="event_remove"
          :is-loading="removing[event.id]"
        />
      </div>
      <sqr-error-note :error="removeError[event.id]" />
    </div>
    <details class="">
      <summary
        v-if="entry"
        class="flex items-center text-2xl py-2 px-4 border-t border-b"
        :style="{ 'border-left': '6px solid ' + entry.color }"
      >
        <span class="mr-2"><fa :icon="['fal', entry.icon]" /></span>
        <span>{{ entry | name }}</span>
      </summary>
      <div v-if="event.entries">
        <div v-for="entry in event.entries" :key="entry.date" class="border-b">
          <div
            :style="{ 'border-left': '6px solid ' + entry.color }"
            class="px-4 pt-2"
          >
            <span class="has-text-weight-semibold mr-2">{{
              entry.date | weekDayLong
            }}</span>
            <span class="has-text-weight-normal"
              >({{ entry.date | week }})</span
            >
          </div>
          <week-day-entry :can-remove="false" :entry="entry" disabled />
        </div>
      </div>
      <div v-else-if="event.entry">
        <week-day-entry :can-remove="false" :entry="event.entry" disabled />
      </div>
    </details>

    <div class="px-4 pt-2 pb-1" v-if="isManagerRW">
      <status-buttons
        class="is-right"
        :status="event.status"
        :is-loading="moderating[event.id]"
        @change="moderate(event, $event)"
      />
      <sqr-error-note class="field" :error="moderateError[event.id]" />
    </div>
    <div class="px-4 pt-2 pb-1 text-right" v-else>
      <status-tag :record="event" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import week from '@/filters/week';
import weekDayLong from '@/filters/weekDayLong';
import name from '@/filters/name';

import SqrButton from '@/sqrd/SqrButton';
import SqrErrorNote from '@/sqrd/SqrErrorNote';
import WeekDayEntry from './WeekDayEntry';
import IntervalName from './IntervalName';
import StatusButtons from './StatusButtons';
import StatusTag from './StatusTag';

export default {
  name: 'EventCard',
  filters: { week, weekDayLong, name },
  components: {
    SqrButton,
    SqrErrorNote,
    IntervalName,
    WeekDayEntry,
    StatusButtons,
    StatusTag,
  },
  props: {
    event: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters('perms', ['isManagerRW', 'isEmployeeRW']),
    ...mapGetters('auth', ['uid']),
    ...mapState('moderate', ['moderating', 'moderateError']),
    ...mapState('remove', ['removing', 'removeError']),
    canRemove() {
      return (
        this.isManagerRW ||
        (this.isEmployeeRW && this.uid === this.event.accountUid)
      );
    },
    disabled() {
      return !(this.isManagerRW || this.isEmployeeRW);
    },
    manager() {
      return this.isManagerRW;
    },
    entry() {
      if (this.event?.entries?.length > 0) {
        return this.event.entries[0];
      }
      return null;
    },
    attCount() {
      return this.event?.attachments?.length;
    },
  },
  methods: {
    ...mapActions('moderate', ['mod']),
    ...mapActions('remove', ['rm']),
    moderate(event, status) {
      this.mod({ type: 'event', doc: event, status });
    },
    removeConfirm() {
      const event = this.event;
      if (confirm(this.$t('event_remove_confirm', { name: name(event) }))) {
        this.rm({ doc: event });
      }
    },
  },
};
</script>
